import { EmbeddedCheckout } from '@stripe/react-stripe-js';
import { EmbeddedCheckoutProvider } from '@stripe/react-stripe-js';
import { Stripe } from '@stripe/stripe-js';
import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';

import { PUBLIC_DOMAINS } from '@spinach-shared/constants';
import { CheckoutBillingScope, ClientEventType, StripePlans } from '@spinach-shared/types';

import { postCreateCheckoutSessionV2 } from '../../../../apis/postCreateCheckoutSession';
import { useExperienceTracking, useGlobalAuthedUser, useStripePlansV2 } from '../../../../hooks';
import { BodyRegularOnboard, HeaderThree, HeaderThreeOnboard, lightTheme } from '../../../../styles';
import { ClientLogger, URLUtil } from '../../../../utils';
import { Anchor, Column, DropDown, LoadingSquares, Row, Spacing } from '../../../common';
import { PrimaryButton } from '../../../stand-up';

const ToggleContainer = styled.div`
    display: flex;
    background: transparent;
    border-radius: 50px;
    border: 1px solid ${lightTheme.neutrals.midnight};
    padding: 4px;
    width: fit-content;
    height: 48px;
`;

const ToggleButton = styled.button<{ active: boolean }>`
    border: none;
    width: auto;
    background: ${(props) => (props.active ? lightTheme.primary.greenLight : 'transparent')};
    color: ${(props) => (props.active ? lightTheme.neutrals.white : lightTheme.neutrals.midnight)};
    padding: 8px 16px;
    border-radius: 100px;
    cursor: pointer;
    transition: all 0.2s ease;
    font-weight: 500;
    box-shadow: ${(props) => (props.active ? '0px 2px 4px rgba(0, 0, 0, 0.1)' : 'none')};
`;

const TogglButtonTitle = styled(HeaderThreeOnboard)<{ active: boolean }>`
    font-size: 18px;
    color: ${(props) => (props.active ? lightTheme.neutrals.white : 'black')};
`;

const TogglButtonSubtitle = styled(BodyRegularOnboard)<{ active: boolean }>`
    font-size: 12px;
    margin-left: 6px;
    color: ${(props) => (props.active ? lightTheme.neutrals.white : 'black')};
`;

const ToggleDivider = styled.div`
    width: 1px;
    height: 24px;
    margin: auto 18px;
    background: ${lightTheme.neutrals.grayLight};
`;

const PlanCardsContainer = styled.div`
    display: flex;
    flex-direction: row;
    align-items: flex-start;
    justify-content: flex-start;
    width: 100%;
`;

const PlanCard = styled.div<{ selected: boolean }>`
    border: 2px solid ${(props) => (props.selected ? lightTheme.primary.greenLight : lightTheme.neutrals.midnight)};
    border-radius: 8px;
    width: 49%;
    max-width: 448px;
    height: 380px;
    display: flex;
    flex-direction: column;
`;

const PlanTitleContainer = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    justify-content: flex-start;
    padding: 11px 24px;
    background: rgba(53, 162, 137, 0.1);
`;

const PlanTitle = styled(HeaderThreeOnboard)`
    font-size: 22px;
    align-self: flex-start;
`;
const PlanSubtitle = styled(BodyRegularOnboard)`
    font-size: 16px;
`;

const PriceText = styled.div`
    font-size: 24px;
    font-weight: 600;
    color: ${lightTheme.primary.midnight};
    margin-bottom: 8px;
`;

const PlanCardContent = styled.div`
    display: flex;
    flex-direction: column;
    align-items: flex-start;
    padding: 0 24px 24px 24px;
    flex: 1;
`;

const SubText = styled(BodyRegularOnboard)`
    font-size: 14px;
    color: black;
    font-size: 16px;
    font-weight: 400;
`;

const BundleOption = styled.div<{ selected: boolean }>`
    margin-bottom: 8px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: flex-start;
`;

const BundleOptionText = styled(BodyRegularOnboard)`
    font-size: 16px;
    font-weight: 700;
    margin-right: 6px;
`;

const RadioCircle = styled.div<{ selected: boolean }>`
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 2px solid #b4b4b4;
    display: flex;
    align-items: center;
    justify-content: center;
    margin-right: 12px;
    background: #f6f6f6;
    &::after {
        content: '';
        width: 16px;
        height: 16px;
        border-radius: 50%;
        background: ${(props) => (props.selected ? lightTheme.primary.greenLight : 'transparent')};
    }
`;

const Spacer = styled.div`
    flex: 1;
`;

const Divider = styled.div`
    width: 100%;
    height: 1px;
    background: ${lightTheme.neutrals.gray};
`;

export function ProAndBusinessPlanSelector({ stripePromise }: { stripePromise: Promise<Stripe | null> }) {
    const plans = useStripePlansV2();
    const [user] = useGlobalAuthedUser();
    const [selectedPlanType, setSelectedPlanType] = useState<'pro' | 'business'>('pro');
    const [selectedPayAsYouGoPlanScope, setSelectedPayAsYouGoPlanScope] = useState<
        CheckoutBillingScope.Team | CheckoutBillingScope.Company
    >(CheckoutBillingScope.Company);
    const [selectedProPlan, setSelectedProPlan] = useState<'payAsYouGo' | 'bundles' | null>(null);
    const [selectedBundlePlanScope, setSelectedBundlePlanScope] = useState<
        CheckoutBillingScope.Team | CheckoutBillingScope.Company
    >(CheckoutBillingScope.Company);
    const [clientSecret, setClientSecret] = useState<string | null>(null);
    const [isFetchingClientSecret, setIsFetchingClientSecret] = useState(false);
    const [isStripeInitialized, setIsStripeInitialized] = useState(false);
    const [hasError, setHasError] = useState(false);
    const planScopeDropdownOptions: {
        code: CheckoutBillingScope.Team | CheckoutBillingScope.Company;
        label: string;
    }[] = [
        { code: CheckoutBillingScope.Company, label: 'Company' },
        { code: CheckoutBillingScope.Team, label: 'Team' },
    ];
    const [selectedBundle, setSelectedBundle] = useState('100');
    const track = useExperienceTracking();
    const [shouldCreateCheckoutSession, setShouldCreateCheckoutSession] = useState(false);
    const hideBillingScopeDropdown = user.isPersonal;
    const checkoutContainerRef = useRef<HTMLDivElement>(null);
    useEffect(() => {
        if (user.isPersonal) {
            setSelectedPayAsYouGoPlanScope(CheckoutBillingScope.Team);
            setSelectedBundlePlanScope(CheckoutBillingScope.Team);
        }
    }, [user.isPersonal]);

    useEffect(() => {
        async function loadStripe() {
            try {
                await stripePromise;
            } catch (e: any) {
                ClientLogger.error('Failed to load stripe', { errorMessage: e.message });
                track(ClientEventType.AIDashboardActivity, {
                    Activity: 'Failed to load stripe on account section',
                });
                setHasError(true);
                return;
            }

            setIsStripeInitialized(true);
        }

        loadStripe();
    }, [stripePromise]);

    useEffect(() => {
        if (!shouldCreateCheckoutSession) {
            return;
        }

        const onPlanSelected = async () => {
            try {
                // pro plan not selected yet
                if (selectedPlanType === 'pro' && selectedProPlan === null) {
                    return;
                }

                await stripePromise;
                setIsFetchingClientSecret(true);
                setClientSecret(null);
                const planScope =
                    selectedPlanType === 'pro'
                        ? selectedProPlan === 'payAsYouGo'
                            ? selectedPayAsYouGoPlanScope
                            : selectedBundlePlanScope
                        : CheckoutBillingScope.Team; // business plans are always team scoped

                const response = await postCreateCheckoutSessionV2({
                    planType: selectedPlanType,
                    proPlanType: selectedProPlan || undefined,
                    bundle: selectedBundle,
                    planScope,
                });
                if (!!response?.clientSecret) {
                    setClientSecret(response.clientSecret);
                    setTimeout(() => {
                        // scroll to the checkoutContainerRef element
                        if (checkoutContainerRef.current) {
                            const checkoutContainer = checkoutContainerRef.current;
                            if (checkoutContainer) {
                                checkoutContainer.scrollIntoView({ behavior: 'smooth' });
                            }
                        }
                    }, 100);
                } else {
                    throw new Error('Failed to get client secret from response');
                }
            } catch (e: any) {
                ClientLogger.error('Failed to create checkout session', { errorMessage: e.message });
                track(ClientEventType.AIDashboardActivity, {
                    Activity: 'Failed to Create Checkout Session',
                });
                setHasError(true);
            } finally {
                setIsFetchingClientSecret(false);
                setShouldCreateCheckoutSession(false);
            }
        };

        onPlanSelected();
        // only run when pro plan is changed or plan type is changed
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [shouldCreateCheckoutSession]);

    return (
        <Column>
            <Row>
                <HeaderThree>Upgrade</HeaderThree>
            </Row>
            <Spacing factor={1 / 2} />
            <Row>
                <BodyRegularOnboard>Pricing questions?</BodyRegularOnboard>
                <Anchor
                    style={{ marginLeft: '5px' }}
                    onClick={() => {
                        track(ClientEventType.AIDashboardClick, {
                            ClickedOn: 'Talk to Sales',
                            Section: 'PlanSelector',
                        });
                        URLUtil.openURL(user.talkToSalesUrl);
                    }}
                >
                    Let&apos;s chat!
                </Anchor>
            </Row>
            <Spacing factor={1 / 2} />
            <ToggleContainer>
                <ToggleButton
                    active={selectedPlanType === 'pro'}
                    onClick={() => {
                        setSelectedPlanType('pro');
                        setClientSecret(null);

                        track(ClientEventType.AIDashboardClick, {
                            ClickedOn: 'PlanToggle',
                            PlanType: 'pro',
                            Section: 'PlanSelector',
                        });
                    }}
                >
                    <Row vCenter style={{ alignItems: 'flex-end' }}>
                        <TogglButtonTitle active={selectedPlanType === 'pro'}>Pro</TogglButtonTitle>{' '}
                        <TogglButtonSubtitle active={selectedPlanType === 'pro'}>(pay per hour)</TogglButtonSubtitle>
                    </Row>
                </ToggleButton>
                <ToggleDivider />
                <ToggleButton
                    active={selectedPlanType === 'business'}
                    onClick={() => {
                        setSelectedPlanType('business');
                        setSelectedProPlan(null);
                        setClientSecret(null);

                        track(ClientEventType.AIDashboardClick, {
                            ClickedOn: 'PlanToggle',
                            PlanType: selectedPlanType,
                            Section: 'PlanSelector',
                        });
                        setShouldCreateCheckoutSession(true);
                    }}
                >
                    <Row vCenter style={{ alignItems: 'flex-end' }}>
                        <TogglButtonTitle active={selectedPlanType === 'business'}>Business</TogglButtonTitle>{' '}
                        <TogglButtonSubtitle active={selectedPlanType === 'business'}>
                            (pay per user)
                        </TogglButtonSubtitle>
                    </Row>
                </ToggleButton>
            </ToggleContainer>
            <Spacing factor={1 / 2} />
            {selectedPlanType === 'pro' ? (
                <PlanCardsContainer>
                    <PlanCard selected={selectedProPlan === 'payAsYouGo'}>
                        <PlanTitleContainer>
                            <PlanTitle>Pay-As-You-Go</PlanTitle>
                            <Spacing factor={1 / 4} />
                            <PlanSubtitle>Pay only for hours used, no commitment</PlanSubtitle>
                        </PlanTitleContainer>
                        <Spacing factor={1} />
                        <PlanCardContent>
                            <PriceText>${plans.pro.payAsYouGo.pricePerHour.toFixed(2)}/hour</PriceText>
                            <Spacer />

                            {!hideBillingScopeDropdown ? (
                                <Row vCenter>
                                    <BodyRegularOnboard
                                        style={{
                                            marginRight: '9px',
                                            fontSize: '15px',
                                            fontWeight: '700',
                                        }}
                                    >
                                        Plan type:
                                    </BodyRegularOnboard>

                                    <DropDown
                                        style={{
                                            width: '110px',
                                        }}
                                        buttonProps={{
                                            labelStyles: {
                                                color: lightTheme.primary.green,
                                            },
                                        }}
                                        title={''}
                                        values={planScopeDropdownOptions}
                                        defaultValue={selectedPayAsYouGoPlanScope}
                                        selected={
                                            planScopeDropdownOptions.find(
                                                (option) => option.code === selectedPayAsYouGoPlanScope
                                            )?.label
                                        }
                                        handleSelection={(code) => {
                                            setSelectedPayAsYouGoPlanScope(
                                                code as CheckoutBillingScope.Team | CheckoutBillingScope.Company
                                            );

                                            setClientSecret(null);
                                            track(ClientEventType.AIDashboardClick, {
                                                ClickedOn: 'Pay-as-you-go Plan Scope Select',
                                                Section: 'PlanSelector',
                                                SelectedPlan: code,
                                            });
                                        }}
                                    />
                                </Row>
                            ) : null}
                            <Spacing factor={1 / 2} />
                            <Divider />
                            <Spacing factor={1 / 2} />
                            <PrimaryButton
                                style={{ margin: 'auto' }}
                                title="Select"
                                onClick={() => {
                                    track(ClientEventType.AIDashboardClick, {
                                        ClickedOn: 'ProPlanSelect',
                                        ProPlanType: selectedProPlan,
                                        Section: 'PlanSelector',
                                    });
                                    setSelectedProPlan('payAsYouGo');
                                    setShouldCreateCheckoutSession(true);
                                }}
                            />
                        </PlanCardContent>
                    </PlanCard>
                    <PlanCard selected={selectedProPlan === 'bundles'} style={{ marginLeft: '12px' }}>
                        <PlanTitleContainer>
                            <PlanTitle>Bundles</PlanTitle>
                            <Spacing factor={1 / 4} />
                            <PlanSubtitle>Purchase hours upfront at discounted rate savings</PlanSubtitle>
                        </PlanTitleContainer>
                        <Spacing factor={1} />
                        <PlanCardContent>
                            {Object.entries(plans.pro.bundles).map(([key, bundle]) => (
                                <BundleOption
                                    key={key}
                                    selected={selectedBundle === key}
                                    onClick={() => {
                                        setSelectedBundle(key);
                                        setClientSecret(null);
                                    }}
                                >
                                    <Row vCenter>
                                        <RadioCircle selected={selectedBundle === key} />
                                        <BundleOptionText>
                                            {key} hours for ${bundle.price}
                                        </BundleOptionText>
                                        <SubText>(${(bundle.price / bundle.hours).toFixed(2)}/hour)</SubText>
                                    </Row>
                                </BundleOption>
                            ))}
                            <Spacer />

                            {!hideBillingScopeDropdown ? (
                                <Row vCenter>
                                    <BodyRegularOnboard
                                        style={{
                                            marginRight: '9px',
                                            fontSize: '15px',
                                            fontWeight: '700',
                                        }}
                                    >
                                        Plan type:
                                    </BodyRegularOnboard>

                                    <DropDown
                                        style={{
                                            width: '110px',
                                        }}
                                        buttonProps={{
                                            labelStyles: {
                                                color: lightTheme.primary.green,
                                            },
                                        }}
                                        title={''}
                                        values={planScopeDropdownOptions}
                                        defaultValue={selectedBundlePlanScope}
                                        selected={
                                            planScopeDropdownOptions.find(
                                                (option) => option.code === selectedBundlePlanScope
                                            )?.label
                                        }
                                        handleSelection={(code) => {
                                            setSelectedBundlePlanScope(code);
                                            setClientSecret(null);
                                            track(ClientEventType.AIDashboardClick, {
                                                ClickedOn: 'Bundle Plan Scope Select',
                                                Section: 'PlanSelector',
                                                SelectedPlan: code,
                                            });
                                        }}
                                    />
                                </Row>
                            ) : null}

                            <Spacing factor={1 / 2} />
                            <Divider />
                            <Spacing factor={1 / 2} />
                            <PrimaryButton
                                style={{ margin: 'auto' }}
                                title="Select"
                                onClick={() => {
                                    track(ClientEventType.AIDashboardClick, {
                                        ClickedOn: 'ProPlanSelect',
                                        ProPlanType: selectedProPlan,
                                        Section: 'PlanSelector',
                                    });
                                    setSelectedProPlan('bundles');
                                    setShouldCreateCheckoutSession(true);
                                }}
                            />
                        </PlanCardContent>
                    </PlanCard>
                </PlanCardsContainer>
            ) : null}
            {clientSecret && isStripeInitialized ? (
                <>
                    <Spacing factor={1} />
                    <div
                        ref={checkoutContainerRef}
                        style={{
                            display: 'flex',
                            justifyContent: 'flex-start',
                            width: '100%',
                            backgroundColor: 'rgb(244, 246, 246)',
                        }}
                    >
                        <div style={{ maxWidth: '1000px', width: '100%' }}>
                            <EmbeddedCheckoutProvider stripe={stripePromise} options={{ clientSecret }}>
                                <EmbeddedCheckout className="spinach-checkout" />
                            </EmbeddedCheckoutProvider>
                        </div>
                    </div>
                </>
            ) : hasError ? (
                <>
                    <Spacing factor={1} />
                    <Row>
                        <BodyRegularOnboard>
                            Oops, something went wrong, please try again later. If the problem persists, please contact
                            support{' '}
                            <Anchor
                                id={'spinach_intercom'}
                                onClick={() => {
                                    track(ClientEventType.AIDashboardClick, {
                                        ClickedOn: `Issue Loading Stripe Help`,
                                    });
                                }}
                            >
                                here
                            </Anchor>
                        </BodyRegularOnboard>
                    </Row>
                </>
            ) : isFetchingClientSecret && (selectedProPlan || selectedPlanType === 'business') ? (
                <>
                    <Spacing factor={1} />
                    <LoadingSquares />
                </>
            ) : null}
        </Column>
    );
}
