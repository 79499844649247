import { Box, Divider, FormControlLabel, Popover, Radio, RadioGroup } from '@material-ui/core';
import { KeyboardArrowDown, KeyboardArrowUp } from '@material-ui/icons';
import moment from 'moment-timezone';
import { ChangeEvent, useState } from 'react';
import styled from 'styled-components';

import {
    AddScribeToAllMeetingKind,
    AddScribeToMeetingsTitlesToMatch,
    ClientEventType,
    UserMetadata,
} from '@spinach-shared/types';
import { TimeUtils } from '@spinach-shared/utils';

import { GlobalModal, patchUser, useExperienceTracking, useGlobalAuthedUser, useGlobalModal } from '../../../../..';
import { removeScribeFromAllMeetings, updateAutomatedScribeSettingsOnCalendarEvents } from '../../../../apis/calendar';
import { useCalendarEvents } from '../../../../hooks/useCalendarEvents';
import { useStoredSeriesListFetcher } from '../../../../hooks/useGlobalStoredSeriesList';
import { BodyRegularOnboardWithTextWrap, ButtonSize, lightTheme } from '../../../../styles';
import { Row, Spacing } from '../../../common';
import { PrimaryButton } from '../../../stand-up';
import { OutlinedButton } from '../../../stand-up/OutlinedButton';
import { KeywordSelection } from './KeywordSelection';

enum FormControlLabelValue {
    RemoveFromAllMeetings = 'remove-from-all-meetings',
    AddToAllMeetings = 'add-to-all-meetings',
    AddToAllMeetingsByKeyword = 'add-to-meetings-by-keyword',
}

const FORM_CONTROL_LABEL_ADD_SCRIBE_TO_ALL_MEETINGS_KIND_MAP: Record<FormControlLabelValue, AddScribeToAllMeetingKind> =
    {
        [FormControlLabelValue.AddToAllMeetingsByKeyword]: AddScribeToAllMeetingKind.MatchedByTitle,
        [FormControlLabelValue.AddToAllMeetings]: AddScribeToAllMeetingKind.All,
        [FormControlLabelValue.RemoveFromAllMeetings]: AddScribeToAllMeetingKind.RemoveFromAllMeetings,
    };

const getAddScribeToAllMeetingKindFromFormControlLabel = (
    formControlLabel: FormControlLabelValue
): AddScribeToAllMeetingKind | undefined => {
    return FORM_CONTROL_LABEL_ADD_SCRIBE_TO_ALL_MEETINGS_KIND_MAP[formControlLabel];
};

const getFormControlLabelFromAddScribeToAllMeetingKind = (
    kind: AddScribeToAllMeetingKind
): FormControlLabelValue | undefined => {
    for (const [key, value] of Object.entries(FORM_CONTROL_LABEL_ADD_SCRIBE_TO_ALL_MEETINGS_KIND_MAP)) {
        if (value === kind) {
            return key as FormControlLabelValue;
        }
    }
    /** @NOTE default value */
    return FormControlLabelValue.AddToAllMeetings;
};

const FormSection = styled.div<{ isSelected: boolean }>`
    display: flex;
    flex-direction: column;
    padding: 8px;
`;

// removing shadow due to how were spacing rows out via gaps in the future meetings view
const MeetingAutomationOutlineButton = styled(OutlinedButton)`
    &:hover {
        box-shadow: none;
    }
    &:active {
        box-shadow: none;
    }
    &:focus {
        box-shadow: none;
    }
`;

const AddToMeetingsButton = styled.span<{ isSelected: boolean; isDisabled?: boolean }>`
    border-radius: 40px;
    padding: 6px;
    background-color: ${(props) => (props.isSelected ? props.theme.secondary.green : props.theme.neutrals.grayLight)};
    margin-right: 5px;
    cursor: ${(props) => (props.isDisabled ? 'default' : 'pointer')};
    display: flex;
    min-width: 30px;
    justify-content: center;
    color: ${(props) => (props.isSelected ? props.theme.neutrals.white : props.theme.primary.midnight)};

    &:hover {
        background-color: ${(props) =>
            props.isDisabled
                ? props.theme.neutrals.grayLight
                : props.isSelected
                ? props.theme.secondary.greenDark
                : props.theme.neutrals.gray};
    }
`;

export function MeetingAutomationDropDown(): JSX.Element {
    const [user, setUser] = useGlobalAuthedUser();
    const [, setGlobalModal] = useGlobalModal();
    const track = useExperienceTracking();
    const [keywords, setKeywords] = useState<AddScribeToMeetingsTitlesToMatch[]>(
        user.automaticAddScribeConfiguration?.kind === AddScribeToAllMeetingKind.MatchedByTitle
            ? user.automaticAddScribeConfiguration.titlesToMatch
            : []
    );
    const { refetch } = useCalendarEvents({
        timeMin: moment.tz(TimeUtils.getTimezoneRegion()).startOf('day').toISOString(),
        timeMax: moment.tz(TimeUtils.getTimezoneRegion()).add(10, 'weeks').endOf('day').toISOString(),
        maxResults: 500,
    });

    const { fetch } = useStoredSeriesListFetcher({ recurringOnly: false });

    const [isAddSpinachDropdownOpen, setIsAddSpinachDropdownOpen] = useState(false);
    const [addSpinachPopoverAnchorEl, setAddSpinachPopoverAnchorEl] = useState<HTMLButtonElement | null>(null);
    const [addToMeetingSelectedKind, setAddToMeetingSelectedKind] = useState<AddScribeToAllMeetingKind | null>(
        user.automaticAddScribeConfiguration?.kind ?? null
    );
    const [isSavingAutomaticSettings, setIsSavingAutomaticSettings] = useState(false);
    const [inputActive, setInputActive] = useState(false);

    const isAddToMeetingsSelected =
        addToMeetingSelectedKind === AddScribeToAllMeetingKind.All ||
        addToMeetingSelectedKind === AddScribeToAllMeetingKind.AllMy ||
        addToMeetingSelectedKind === AddScribeToAllMeetingKind.Internal ||
        addToMeetingSelectedKind === AddScribeToAllMeetingKind.External;

    const onAutomationRadioSelect = (selected: ChangeEvent<HTMLInputElement>) => {
        const selectedValue = selected.target.value as FormControlLabelValue;
        const mappedValue = getAddScribeToAllMeetingKindFromFormControlLabel(selectedValue);
        track(ClientEventType.AIDashboardClick, {
            ClickedOn: 'Automatic Add Scribe To Meeting Radio Button',
            SelectedFormLabel: selectedValue,
            AddScribeToAllMeetingKind: mappedValue,
            ExistingAddScribeToAllMeetingKind: addToMeetingSelectedKind,
            Keywords: keywords,
        });

        if (!mappedValue) {
            return;
        }
        if (mappedValue !== AddScribeToAllMeetingKind.MatchedByTitle) {
            setKeywords([]);
            setInputActive(false);
        }
        setAddToMeetingSelectedKind(mappedValue);
    };

    const addToMeetingsButtonClick = (
        addToMeetingsAddScribeToMeetingKind:
            | AddScribeToAllMeetingKind.All
            | AddScribeToAllMeetingKind.AllMy
            | AddScribeToAllMeetingKind.Internal
            | AddScribeToAllMeetingKind.External
            | null
    ) => {
        if (!!addToMeetingSelectedKind && !isAddToMeetingsSelected) {
            return;
        }
        setAddToMeetingSelectedKind(addToMeetingsAddScribeToMeetingKind);
    };

    return (
        <BodyRegularOnboardWithTextWrap style={{ textAlign: 'center' }}>
            <span style={{ marginRight: '5px', fontSize: '16px', fontWeight: 600 }}>Auto-record:</span>
            <MeetingAutomationOutlineButton
                title={
                    user.automaticAddScribeConfiguration?.kind === AddScribeToAllMeetingKind.All
                        ? 'All meetings'
                        : user.automaticAddScribeConfiguration?.kind === AddScribeToAllMeetingKind.AllMy
                        ? 'My meetings'
                        : user.automaticAddScribeConfiguration?.kind === AddScribeToAllMeetingKind.Internal
                        ? 'Internal meetings'
                        : user.automaticAddScribeConfiguration?.kind === AddScribeToAllMeetingKind.External
                        ? 'External meetings'
                        : user.automaticAddScribeConfiguration?.kind === AddScribeToAllMeetingKind.MatchedByTitle
                        ? 'Meetings with keywords'
                        : 'Selected'
                }
                labelStyles={{
                    display: 'flex',
                    alignItems: 'center',
                    color: lightTheme.primary.midnight,
                    fontWeight: 500,
                    gap: '10px',
                }}
                style={{
                    width: 'unset',
                    padding: '6px 20px',
                }}
                onClick={(e) => {
                    track(ClientEventType.AIDashboardClick, {
                        ClickedOn: 'Meeting Automation Dropdown',
                        IsUsingRecallCalendarV2: !!user.metadata.isUsingRecallCalendarV2,
                        IsGoogleCalendarEnabled: !!user.integrationSettings?.googleSettings?.isCalendarEnabled,
                        IsMicrosoftCalendarEnabled: !!user.integrationSettings?.microsoftSettings?.isCalendarEnabled,
                        IsAddSpinachDropdownOpen: isAddSpinachDropdownOpen,
                    });
                    if (!user.metadata.isUsingRecallCalendarV2 || !user.isAuthedForAnyCalendar) {
                        setGlobalModal(GlobalModal.UpgradeToRecallCalendarV2);
                    } else {
                        setIsAddSpinachDropdownOpen(!isAddSpinachDropdownOpen);
                        setAddSpinachPopoverAnchorEl(e.currentTarget);
                    }
                }}
                endIcon={
                    isAddSpinachDropdownOpen ? (
                        <KeyboardArrowUp fontSize="small" />
                    ) : (
                        <KeyboardArrowDown fontSize="small" />
                    )
                }
            />
            <Popover
                open={isAddSpinachDropdownOpen}
                anchorEl={addSpinachPopoverAnchorEl}
                onClose={() => {
                    setIsAddSpinachDropdownOpen(false);
                    setAddToMeetingSelectedKind(user.automaticAddScribeConfiguration?.kind || null);
                }}
                anchorOrigin={{
                    vertical: 'bottom',
                    horizontal: 'left',
                }}
                PaperProps={{
                    style: {
                        borderRadius: '10px',
                    },
                }}
            >
                <Box style={{ padding: '8px' }}>
                    <RadioGroup
                        onChange={onAutomationRadioSelect}
                        defaultValue={getFormControlLabelFromAddScribeToAllMeetingKind(
                            user.automaticAddScribeConfiguration?.kind ?? AddScribeToAllMeetingKind.All
                        )}
                        name="radio-buttons-group"
                    >
                        <FormSection isSelected={!addToMeetingSelectedKind || isAddToMeetingsSelected}>
                            <FormControlLabel
                                value={getFormControlLabelFromAddScribeToAllMeetingKind(AddScribeToAllMeetingKind.All)}
                                control={<Radio />}
                                label="Auto-record:"
                            />
                            <Row vCenter>
                                <AddToMeetingsButton
                                    isDisabled={Boolean(!!addToMeetingSelectedKind && !isAddToMeetingsSelected)}
                                    isSelected={addToMeetingSelectedKind === AddScribeToAllMeetingKind.All}
                                    onClick={() => {
                                        addToMeetingsButtonClick(AddScribeToAllMeetingKind.All);
                                    }}
                                >
                                    All meetings
                                </AddToMeetingsButton>
                                <AddToMeetingsButton
                                    isDisabled={Boolean(!!addToMeetingSelectedKind && !isAddToMeetingsSelected)}
                                    isSelected={addToMeetingSelectedKind === AddScribeToAllMeetingKind.AllMy}
                                    onClick={() => {
                                        addToMeetingsButtonClick(AddScribeToAllMeetingKind.AllMy);
                                    }}
                                >
                                    Meetings I organize
                                </AddToMeetingsButton>
                                <AddToMeetingsButton
                                    isDisabled={Boolean(!!addToMeetingSelectedKind && !isAddToMeetingsSelected)}
                                    isSelected={addToMeetingSelectedKind === AddScribeToAllMeetingKind.Internal}
                                    onClick={() => {
                                        addToMeetingsButtonClick(AddScribeToAllMeetingKind.Internal);
                                    }}
                                >
                                    Internal only
                                </AddToMeetingsButton>
                                <AddToMeetingsButton
                                    isDisabled={Boolean(!!addToMeetingSelectedKind && !isAddToMeetingsSelected)}
                                    isSelected={addToMeetingSelectedKind === AddScribeToAllMeetingKind.External}
                                    onClick={() => {
                                        addToMeetingsButtonClick(AddScribeToAllMeetingKind.External);
                                    }}
                                >
                                    External only
                                </AddToMeetingsButton>
                                <AddToMeetingsButton
                                    isDisabled={Boolean(!!addToMeetingSelectedKind && !isAddToMeetingsSelected)}
                                    isSelected={!addToMeetingSelectedKind}
                                    onClick={() => {
                                        addToMeetingsButtonClick(null);
                                    }}
                                >
                                    Selected
                                </AddToMeetingsButton>
                            </Row>
                            <Spacing factor={1 / 3} />
                        </FormSection>
                        <Divider />
                        <FormSection isSelected={addToMeetingSelectedKind === AddScribeToAllMeetingKind.MatchedByTitle}>
                            <Spacing factor={1 / 3} />
                            <FormControlLabel
                                value={getFormControlLabelFromAddScribeToAllMeetingKind(
                                    AddScribeToAllMeetingKind.MatchedByTitle
                                )}
                                control={<Radio />}
                                label="Auto-record meetings with titles containing these keywords:"
                            />
                            <KeywordSelection
                                inputActive={inputActive}
                                setInputActive={setInputActive}
                                isDisabled={addToMeetingSelectedKind !== AddScribeToAllMeetingKind.MatchedByTitle}
                                setKeywords={setKeywords}
                                keywords={keywords}
                            />
                            <Spacing factor={1 / 3} />
                        </FormSection>
                        <Divider />
                        <FormSection
                            isSelected={addToMeetingSelectedKind === AddScribeToAllMeetingKind.RemoveFromAllMeetings}
                        >
                            <Spacing factor={1 / 3} />
                            <FormControlLabel
                                value={getFormControlLabelFromAddScribeToAllMeetingKind(
                                    AddScribeToAllMeetingKind.RemoveFromAllMeetings
                                )}
                                control={<Radio />}
                                label="Remove from all meetings"
                            />
                        </FormSection>
                    </RadioGroup>
                    <Row centered>
                        <PrimaryButton
                            isLoading={isSavingAutomaticSettings}
                            disabled={addToMeetingSelectedKind === user.automaticAddScribeConfiguration?.kind}
                            title="Save settings"
                            size={ButtonSize.Small}
                            onClick={async () => {
                                try {
                                    setIsSavingAutomaticSettings(true);
                                    setIsAddSpinachDropdownOpen(false);
                                    setAddSpinachPopoverAnchorEl(null);
                                    setGlobalModal(GlobalModal.LoadingBackdrop);

                                    track(ClientEventType.AIDashboardClick, {
                                        ClickedOn: 'Save Add Spinach To All Meetings',
                                        AddToAllMeetingsKind: addToMeetingSelectedKind,
                                    });
                                    const automaticAddScribeConfiguration =
                                        !addToMeetingSelectedKind ||
                                        addToMeetingSelectedKind === AddScribeToAllMeetingKind.RemoveFromAllMeetings
                                            ? {}
                                            : addToMeetingSelectedKind === AddScribeToAllMeetingKind.MatchedByTitle
                                            ? {
                                                  kind: addToMeetingSelectedKind,
                                                  titlesToMatch: keywords,
                                              }
                                            : { kind: addToMeetingSelectedKind };

                                    let patchUserMetadata: Partial<UserMetadata> = {};
                                    if (
                                        !addToMeetingSelectedKind ||
                                        addToMeetingSelectedKind === AddScribeToAllMeetingKind.RemoveFromAllMeetings
                                    ) {
                                        patchUserMetadata = {
                                            automaticAddScribeConfiguration: { kind: null },
                                        };
                                        setAddToMeetingSelectedKind(null);
                                    } else {
                                        patchUserMetadata = {
                                            automaticAddScribeConfiguration,
                                        };
                                    }

                                    const updatedUserResponse = await patchUser({
                                        metadata: patchUserMetadata,
                                    });
                                    if (updatedUserResponse.user) {
                                        setUser(updatedUserResponse.user);
                                        if (
                                            addToMeetingSelectedKind === AddScribeToAllMeetingKind.RemoveFromAllMeetings
                                        ) {
                                            await removeScribeFromAllMeetings();
                                            // need to refresh series state after udpate occurs

                                            refetch();
                                        } else if (automaticAddScribeConfiguration) {
                                            // need to refresh series state after udpate occurs
                                            await updateAutomatedScribeSettingsOnCalendarEvents(
                                                automaticAddScribeConfiguration
                                            );
                                            refetch();
                                        }
                                    }

                                    await fetch();

                                    setIsSavingAutomaticSettings(false);
                                    setGlobalModal(null);
                                } catch (e) {
                                    setIsSavingAutomaticSettings(false);
                                    setGlobalModal(null);
                                }
                            }}
                        />
                    </Row>
                    <Spacing factor={1 / 3} />
                </Box>
            </Popover>
        </BodyRegularOnboardWithTextWrap>
    );
}
