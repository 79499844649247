import { IconButton, List, ListItem, ListItemSecondaryAction, ListItemText, MenuItem } from '@material-ui/core';
import {
    Add,
    Check,
    ChevronLeft,
    ChevronRight,
    Close,
    Home,
    ListAlt,
    PeopleOutline,
    VolumeOff,
    VolumeUp,
} from '@material-ui/icons';
import DeleteIcon from '@material-ui/icons/Delete';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import EditIcon from '@material-ui/icons/Edit';
import React, { useEffect, useRef, useState } from 'react';
import { DragDropContext, Draggable, Droppable } from 'react-beautiful-dnd';
import { useNavigate } from 'react-router-dom';
import { Socket } from 'socket.io-client';
import styled from 'styled-components';

import {
    ClientEventType,
    ClientSocketEvent,
    VideoAgentControlAction,
    VideoAgentControlCommand,
    VideoAgentSessionTopic,
} from '@spinach-shared/types';
import { isLocalStage } from '@spinach-shared/utils';

import SpinachLogo from '../../assets/logo-spinachio.svg';
import { useActivityTracking, useGlobalAuthedUser, useGlobalVideoAgent } from '../../hooks';
import { lightTheme } from '../../styles/theme';
import { AgentCommandType } from '../../types/agent';
import { Row } from '../common';
import { DropDown } from '../common/DropDown';

const ControlContainer = styled.div`
    max-width: 600px;
    margin: 0 auto;
    padding: 20px;
    font-family: Arial, sans-serif;
    height: calc(100vh - 40px);
    display: flex;
    flex-direction: column;
    overflow: hidden;
`;

const BottomNavigation = styled.div`
    position: fixed;
    bottom: 0;
    left: 0;
    right: 0;
    padding: 15px;
    background: white;
    border-top: 1px solid rgba(11, 103, 74, 0.1);
    box-shadow: 0 -4px 16px rgba(0, 0, 0, 0.06);
    display: flex;
    flex-direction: column;
    gap: 12px;
    align-items: center;
    z-index: 1;

    ${Row} {
        width: 100%;
        max-width: 600px;
        padding: 0 20px;
        gap: 8px;
    }
`;

const ControlButton = styled.button<{ isActive?: boolean; variant?: 'primary' | 'secondary' | 'tertiary' }>`
    background: white;
    color: ${(props) => {
        if (props.variant === 'primary') return props.isActive ? props.theme.primary.midnight : '#4CAF50';
        return props.theme.primary.midnight;
    }};
    border: 1px solid
        ${(props) => {
            if (props.variant === 'primary') return props.isActive ? props.theme.primary.midnight : '#4CAF50';
            return props.theme.primary.midnight;
        }};
    padding: 8px 10px;
    border-radius: 4px;
    cursor: pointer;
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 8px;
    font-weight: bold;
    transition: all 0.3s ease;
    font-size: 14px;
    min-width: 180px;
    height: 36px;
    width: 100%;

    @media (max-width: 600px) {
        min-width: 120px;
        padding: 8px 6px;
        font-size: 12px;
        gap: 4px;
    }

    @media (max-width: 480px) {
        min-width: 100px;
        padding: 8px 4px;
        font-size: 11px;
        gap: 2px;
    }

    &:hover {
        transform: translateY(-2px);
        box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
    }

    &:active {
        transform: translateY(0);
        box-shadow: 0 1px 2px rgba(0, 0, 0, 0.1);
    }

    span {
        font-size: 18px;
        line-height: 1;
        display: flex;
        align-items: center;

        @media (max-width: 600px) {
            font-size: 16px;
        }

        @media (max-width: 480px) {
            font-size: 14px;
        }
    }

    svg {
        @media (max-width: 600px) {
            font-size: 18px !important;
        }

        @media (max-width: 480px) {
            font-size: 16px !important;
        }
    }
`;

const NavButton = styled(ControlButton)`
    min-width: 180px;
    flex: 1;

    @media (max-width: 600px) {
        min-width: 120px;
    }

    @media (max-width: 480px) {
        min-width: 100px;
    }
`;

const StartMeetingButton = styled(ControlButton)`
    background-color: #4caf50;
    color: white;
    border-color: #4caf50;
    min-width: 180px;
    width: 100%;
    max-width: 600px;
    margin: 0 20px;

    &:hover {
        background-color: #45a049;
    }

    @media (max-width: 600px) {
        min-width: 120px;
    }

    @media (max-width: 480px) {
        min-width: 100px;
    }
`;

const TopicListContainer = styled.div`
    min-height: 0;
    overflow: hidden;
    margin: 0 0 0px 0;
    padding-top: 10px;
    display: flex;
    flex-direction: column;
`;

const TopicListHeader = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 -4px 8px -4px;

    @media (max-width: 600px) {
        margin: 0 -2px 8px -2px;
    }
`;

const HeaderSection = styled.div`
    flex: 1;
    display: flex;
    align-items: center;
    padding: 0 4px;

    &:first-child {
        justify-content: flex-start;
    }

    &:nth-child(2) {
        justify-content: center;
    }

    &:last-child {
        justify-content: flex-end;
    }

    @media (max-width: 600px) {
        padding: 0 2px;
    }
`;

const TopicList = styled(List)`
    height: 100%;
    overflow-y: auto;
    background-color: transparent;
    border-radius: 4px;
    padding: 0;
    position: relative;

    /* Custom scrollbar styling */
    &::-webkit-scrollbar {
        width: 8px;
        background: transparent;
    }

    &::-webkit-scrollbar-thumb {
        background: rgba(255, 255, 255, 0.2);
        border-radius: 4px;

        &:hover {
            background: rgba(255, 255, 255, 0.3);
        }
    }

    /* For Firefox */
    scrollbar-width: thin;
    scrollbar-color: rgba(255, 255, 255, 0.2) transparent;
`;

const StyledListItem = styled(ListItem)<{ isDragging: boolean; isSelected: boolean; isHovered?: boolean }>`
    background: ${(props) =>
        props.isSelected
            ? 'rgba(11, 103, 74, 0.1)'
            : props.isDragging
            ? 'rgba(11, 103, 74, 0.05)'
            : 'rgba(11, 103, 74, 0.02)'};
    color: ${(props) => props.theme.primary.midnight};
    margin: 5px 0;
    border-radius: 4px !important;
    position: relative;
    padding-left: 3px;
    border: ${(props) => (props.isSelected ? '1px solid #0B674A' : '1px solid rgba(11, 103, 74, 0.4)')} !important;
    margin-top: 4px !important;
    margin-bottom: 4px !important;
    transition: all 0.2s ease-in-out !important;
    padding-left: 8px !important;

    &:hover {
        ${(props) =>
            !props.isDragging &&
            `
            border-color: #0B674A !important;
            background: rgba(11, 103, 74, 0.1);
            transform: translateX(4px);
            width: calc(100% - 4px);
        `}
    }
`;

const AddTopicButton = styled.button`
    width: 100%;
    padding: 8px 12px;
    height: 50px;
    background: rgba(11, 103, 74, 0.05);
    border: 1px solid rgba(11, 103, 74, 0.4);
    border-radius: 4px;
    cursor: pointer;
    text-align: left;
    transition: all 0.2s ease-in-out;
    color: ${(props) => props.theme.primary.midnight};
    display: flex;
    align-items: center;
    font-size: 16px;

    &:hover {
        background: rgba(11, 103, 74, 0.1);
        border-color: #0b674a;
        transform: translateX(4px);
        width: calc(100% - 4px);
    }
`;

const Input = styled.input`
    width: 100%;
    padding: 10px;
    border: 1px solid white;
    border-radius: 4px;
    background: transparent;
    color: white;

    &::placeholder {
        color: rgba(255, 255, 255, 0.7);
    }
`;

const StyledListItemText = styled(ListItemText)<{ isSelected: boolean }>`
    .MuiListItemText-primary {
        font-weight: ${(props) => (props.isSelected ? 'bold' : 'normal')};
        color: ${(props) => props.theme.primary.midnight};
    }
`;

const DragHandleStyled = styled.div<{ isHovered: boolean }>`
    color: ${(props) => props.theme.primary.midnight};
    cursor: grab;
    display: flex;
    align-items: center;
    margin-right: 8px;
    opacity: ${(props) => (props.isHovered ? 1 : 0)};
    transition: opacity 0.2s ease-in-out;

    &:active {
        cursor: grabbing;
    }
`;

const TopicIndicator = styled.div<{ isActive: boolean }>`
    width: 3px;
    height: 100%;
    background-color: ${(props) => (props.isActive ? '#4CAF50' : 'transparent')};
    position: absolute;
    left: 0;
    top: 0;
`;

const StyledListItemSecondaryAction = styled(ListItemSecondaryAction)<{ isHovered: boolean }>`
    .MuiIconButton-root {
        color: ${(props) => props.theme.primary.midnight};
        opacity: ${(props) => (props.isHovered ? 1 : 0)};
        transition: opacity 0.2s ease-in-out;
    }
`;

const AddTopicContainer = styled.div`
    display: flex;
    height: 44px;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 60px;
    margin-top: 5px;
`;

const TopicInputContainer = styled.div`
    position: relative;
    flex: 1;
    display: flex;
    align-items: center;
    gap: 8px;
`;

const TopicInput = styled(Input)`
    background: rgba(11, 103, 74, 0.1) !important;
    backdrop-filter: blur(8px);
    color: ${(props) => props.theme.primary.midnight} !important;
    height: 28px;
    flex: 1;

    &::placeholder {
        color: rgba(29, 29, 39, 0.7);
    }

    &:focus {
        border-color: ${(props) => props.theme.primary.green};
    }
`;

const ActionButton = styled(IconButton)`
    &.MuiIconButton-root {
        width: 48px;
        height: 48px;
        padding: 4px;
        color: ${(props) => props.theme.primary.midnight};
        background: rgba(11, 103, 74, 0.1);
        border-radius: 4px;
        border: none;

        &:hover {
            background: rgba(11, 103, 74, 0.2);
        }

        svg {
            font-size: 20px;
        }
    }
`;

const RecordingControl = styled(ControlButton).attrs({ variant: 'primary' })``;

interface MeetingOption {
    botId: string;
    videoToken: string;
    seriesId: string;
    meetingTitle: string;
}

export function VideoAgentControlPanel({
    botId,
    videoToken,
    seriesId,
    socket,
    availableMeetings,
}: {
    botId: string;
    videoToken: string;
    seriesId: string;
    socket: Socket;
    availableMeetings: MeetingOption[];
}) {
    const { session } = useGlobalVideoAgent();
    const [user] = useGlobalAuthedUser();

    const trackActivity = useActivityTracking();

    const [newTopicName, setNewTopicName] = useState<string>('');
    const [isAddingTopic, setIsAddingTopic] = useState(false);

    const inputRef = useRef<HTMLInputElement>(null);

    const [videoUrl, setVideoUrl] = useState('');

    const [hoveredId, setHoveredId] = useState<string | null>(null);

    const sendCommand = (command: VideoAgentControlCommand) => {
        if (isLocalStage()) {
            console.log('Sending command via postMessage:', command);
            if (window.opener) {
                window.opener.postMessage({ type: 'local.VideoAgentControlCommand', command }, '*');
            } else {
                console.error('No parent window found. Make sure the control page is opened as a popup.');
            }
        } else if (socket && botId) {
            socket.emit(ClientSocketEvent.VideoAgentControlling, { botId, ...command });
        }
    };

    useEffect(() => {
        if (isAddingTopic && inputRef.current) {
            inputRef.current.focus();
        }
    }, [isAddingTopic]);

    const handleAddTopic = () => {
        if (newTopicName.trim()) {
            trackActivity(ClientEventType.VideoAgentActivity, 'Add Topic', {
                TriggeredBy: AgentCommandType.RemoteControl,
                ...session?.analyticsPayload,
            });
            sendCommand({ action: VideoAgentControlAction.AddTopic, topicName: newTopicName.trim() });
            setNewTopicName('');
            setIsAddingTopic(false);
        }
    };

    const handleCancelAddTopic = () => {
        setIsAddingTopic(false);
        setNewTopicName('');
    };

    const handleKeyPress = (event: React.KeyboardEvent<HTMLInputElement>) => {
        if (event.key === 'Enter') {
            handleAddTopic();
        }
    };

    const handleEditTopic = (topicId: string, newName: string) => {
        trackActivity(ClientEventType.VideoAgentActivity, 'Edit Topic', {
            TriggeredBy: AgentCommandType.RemoteControl,
            When: session.currentTopic?.id ? 'After Agenda Started' : 'Before Agenda Started',
            ...session?.analyticsPayload,
        });
        sendCommand({ action: VideoAgentControlAction.EditTopic, topicId, topicName: newName });
    };

    const handleDeleteTopic = (topicId: string) => {
        trackActivity(ClientEventType.VideoAgentActivity, 'Delete Topic', {
            TriggeredBy: AgentCommandType.RemoteControl,
            When: session.currentTopic?.id ? 'After Agenda Started' : 'Before Agenda Started',
            ...session?.analyticsPayload,
        });
        sendCommand({ action: VideoAgentControlAction.DeleteTopic, topicId });
    };

    const handleToggleRecording = () => {
        trackActivity(ClientEventType.VideoAgentActivity, 'Toggle Recording', {
            TriggeredBy: AgentCommandType.RemoteControl,
            When: session.currentTopic?.id ? 'After Agenda Started' : 'Before Agenda Started',
            ...session?.analyticsPayload,
        });
        sendCommand({ action: VideoAgentControlAction.ToggleRecording });
    };

    const handleToggleRoundtable = () => {
        trackActivity(ClientEventType.VideoAgentActivity, 'Toggle Roundtable', {
            TriggeredBy: AgentCommandType.RemoteControl,
            When: session.currentTopic?.id ? 'After Agenda Started' : 'Before Agenda Started',
            ...session?.analyticsPayload,
        });
        sendCommand({ action: VideoAgentControlAction.ToggleRoundtable });
    };

    const handleStopAnyActiveAudio = () => {
        trackActivity(ClientEventType.VideoAgentActivity, 'Stop Any Active Audio', {
            TriggeredBy: AgentCommandType.RemoteControl,
            ...session?.analyticsPayload,
        });
        sendCommand({ action: VideoAgentControlAction.Stop });
    };

    const handleToggleAudioAcknowledgementDisabled = () => {
        trackActivity(ClientEventType.VideoAgentActivity, 'Toggle Audio Acknowledgement Disabled', {
            TriggeredBy: AgentCommandType.RemoteControl,
            When: session.currentTopic?.id ? 'After Agenda Started' : 'Before Agenda Started',
            ...session?.analyticsPayload,
        });
        sendCommand({ action: VideoAgentControlAction.ToggleAudioAcknowledgementDisabled });
    };

    const handleToggleAudioTimeCheckDisabled = () => {
        trackActivity(ClientEventType.VideoAgentActivity, 'Toggle Audio Time Check Disabled', {
            TriggeredBy: AgentCommandType.RemoteControl,
            When: session.currentTopic?.id ? 'After Agenda Started' : 'Before Agenda Started',
            ...session?.analyticsPayload,
        });
        sendCommand({ action: VideoAgentControlAction.ToggleAudioTimeCheckDisabled });
    };

    const reorder = (list: VideoAgentSessionTopic[], startIndex: number, endIndex: number) => {
        const result = Array.from(list);
        const [removed] = result.splice(startIndex, 1);
        result.splice(endIndex, 0, removed);
        return result;
    };

    const onDragEnd = (result: any) => {
        if (!result.destination || !session?.topics) {
            return;
        }

        const items = reorder(session.topics, result.source.index, result.destination.index);

        trackActivity(ClientEventType.VideoAgentActivity, 'Reorder Topics', {
            TriggeredBy: AgentCommandType.RemoteControl,
            When: session.currentTopic?.id ? 'After Agenda Started' : 'Before Agenda Started',
            ...session?.analyticsPayload,
        });

        sendCommand({
            action: VideoAgentControlAction.ReorderTopics,
            topics: items.map((topic) => topic.id),
        });
    };

    const handleAddTopicClick = () => {
        setIsAddingTopic(true);
    };

    const handleStartMeeting = () => {
        trackActivity(ClientEventType.VideoAgentActivity, 'Start Agenda', {
            TriggeredBy: AgentCommandType.RemoteControl,
            ...session?.analyticsPayload,
        });
        sendCommand({ action: VideoAgentControlAction.Next });
    };
    const navigate = useNavigate();

    const handlePlayVideo = () => {
        sendCommand({ action: VideoAgentControlAction.PlayVideo, videoUrl });
    };

    const handleMeetingChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        const newBotId = event.target.value as string;
        navigate(`/control/${newBotId}`);
        trackActivity(ClientEventType.VideoAgentActivity, 'Change Meeting', {
            TriggeredBy: AgentCommandType.RemoteControl,
            ...session?.analyticsPayload,
        });
    };

    const handleTopicClick = (index: number) => {
        trackActivity(ClientEventType.VideoAgentActivity, 'Jump To Topic', {
            TriggeredBy: AgentCommandType.RemoteControl,
            When: session.currentTopic?.id ? 'After Agenda Started' : 'Before Agenda Started',
            ...session?.analyticsPayload,
        });
        sendCommand({ action: VideoAgentControlAction.JumpToTopic, topicIndex: index });
    };

    return (
        <ControlContainer>
            <div
                style={{
                    textAlign: 'center',
                    marginBottom: '20px',
                }}
            >
                <img
                    src={SpinachLogo}
                    alt="SpinachAI"
                    style={{
                        height: '24px',
                        verticalAlign: 'middle',
                        marginLeft: '5px',
                    }}
                />
            </div>

            <Row centered={true} style={{ marginBottom: '20px' }}>
                <DropDown
                    title="Select Meeting"
                    values={availableMeetings.map((meeting) => ({
                        code: meeting.botId,
                        value: meeting.meetingTitle,
                        label: meeting.meetingTitle,
                    }))}
                    selected={availableMeetings.find((meeting) => meeting.botId === botId)?.meetingTitle}
                    handleSelection={(newBotId) => {
                        if (typeof newBotId === 'string') {
                            navigate(`/control/${newBotId}`);
                            trackActivity(ClientEventType.VideoAgentActivity, 'Change Meeting', {
                                TriggeredBy: AgentCommandType.RemoteControl,
                                ...session?.analyticsPayload,
                            });
                        }
                    }}
                    style={{
                        minWidth: '200px',
                        marginBottom: '20px',
                        borderRadius: '4px',
                    }}
                    buttonProps={{
                        style: {
                            borderRadius: '4px',
                            border: `1px solid ${lightTheme.neutrals.grayDark}`,
                            padding: '8px 16px',
                            width: '100%',
                            boxShadow: 'none',
                        },
                    }}
                />
            </Row>

            <TopicListContainer>
                <TopicListHeader>
                    <HeaderSection>
                        <ControlButton
                            isActive={session.isRoundtableEnabled}
                            onClick={handleToggleRoundtable}
                            variant="secondary"
                        >
                            {session.isRoundtableEnabled ? (
                                <>
                                    <PeopleOutline style={{ fontSize: 20 }} />
                                    Roundtable On
                                </>
                            ) : (
                                <>
                                    <ListAlt style={{ fontSize: 20 }} />
                                    Roundtable Off
                                </>
                            )}
                        </ControlButton>
                    </HeaderSection>

                    <HeaderSection>
                        <RecordingControl isActive={!(session.isPaused ?? false)} onClick={handleToggleRecording}>
                            {session.isPaused ? (
                                <>
                                    <span>⏵</span>
                                    Resume Recording
                                </>
                            ) : (
                                <>
                                    <span>⏸</span>
                                    Pause Recording
                                </>
                            )}
                        </RecordingControl>
                    </HeaderSection>

                    <HeaderSection>
                        <ControlButton
                            isActive={session.isAudioAcknowledgementDisabled}
                            onClick={handleToggleAudioAcknowledgementDisabled}
                            variant="tertiary"
                        >
                            {session.isAudioAcknowledgementDisabled ? (
                                <>
                                    <VolumeOff />
                                    Sound Effects Off
                                </>
                            ) : (
                                <>
                                    <VolumeUp />
                                    Sound Effects On
                                </>
                            )}
                        </ControlButton>
                    </HeaderSection>
                </TopicListHeader>

                <DragDropContext onDragEnd={onDragEnd}>
                    <Droppable droppableId="topicList">
                        {(provided, snapshot) => (
                            <TopicList ref={provided.innerRef} {...provided.droppableProps}>
                                <StyledListItem
                                    isDragging={false}
                                    isSelected={session.isLobbyPhase ?? false}
                                    onClick={() => handleTopicClick(-1)}
                                    button
                                >
                                    <TopicIndicator isActive={session.isLobbyPhase ?? false} />
                                    <Home style={{ marginRight: '8px' }} htmlColor={lightTheme.primary.midnight} />
                                    <StyledListItemText primary="Lobby" isSelected={session.isLobbyPhase ?? false} />
                                </StyledListItem>
                                {session.topics.map((topic: VideoAgentSessionTopic, index: number) => (
                                    <Draggable key={topic.id} draggableId={topic.id} index={index}>
                                        {(provided, snapshot) => (
                                            <StyledListItem
                                                ref={provided.innerRef}
                                                {...provided.draggableProps}
                                                isDragging={snapshot.isDragging}
                                                isSelected={index === session.currentTopicIndex}
                                                isHovered={hoveredId === topic.id}
                                                button
                                                onClick={() => handleTopicClick(index)}
                                                onMouseEnter={() => setHoveredId(topic.id)}
                                                onMouseLeave={() => setHoveredId(null)}
                                            >
                                                <TopicIndicator isActive={index === session.currentTopicIndex} />
                                                <DragHandleStyled
                                                    {...provided.dragHandleProps}
                                                    isHovered={hoveredId === topic.id}
                                                >
                                                    <DragIndicatorIcon />
                                                </DragHandleStyled>
                                                <StyledListItemText
                                                    primary={topic.title}
                                                    isSelected={index === session.currentTopicIndex}
                                                />
                                                <StyledListItemSecondaryAction
                                                    onMouseEnter={() => setHoveredId(topic.id)}
                                                    onMouseLeave={() => setHoveredId(null)}
                                                    isHovered={hoveredId === topic.id}
                                                >
                                                    <IconButton
                                                        edge="end"
                                                        aria-label="edit"
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            handleEditTopic(
                                                                topic.id,
                                                                prompt('Enter new topic name', topic.title) ||
                                                                    topic.title
                                                            );
                                                        }}
                                                    >
                                                        <EditIcon htmlColor={lightTheme.primary.midnight} />
                                                    </IconButton>
                                                    <IconButton
                                                        edge="end"
                                                        aria-label="delete"
                                                        onClick={(e) => {
                                                            e.stopPropagation();
                                                            handleDeleteTopic(topic.id);
                                                        }}
                                                    >
                                                        <DeleteIcon htmlColor={lightTheme.primary.midnight} />
                                                    </IconButton>
                                                </StyledListItemSecondaryAction>
                                            </StyledListItem>
                                        )}
                                    </Draggable>
                                ))}
                                {provided.placeholder}
                            </TopicList>
                        )}
                    </Droppable>
                </DragDropContext>
            </TopicListContainer>

            <AddTopicContainer>
                {isAddingTopic ? (
                    <TopicInputContainer>
                        <TopicInput
                            ref={inputRef}
                            type="text"
                            value={newTopicName}
                            onChange={(e) => setNewTopicName(e.target.value)}
                            onKeyPress={handleKeyPress}
                            onKeyDown={(event) => {
                                if (event.key === 'Escape') {
                                    handleCancelAddTopic();
                                }
                            }}
                            placeholder="Enter topic name"
                            style={{
                                width: '100%',
                            }}
                        />
                        <ActionButton onClick={handleAddTopic} disabled={newTopicName.trim() === ''}>
                            <Check htmlColor={lightTheme.primary.green} />
                        </ActionButton>
                        <ActionButton onClick={handleCancelAddTopic}>
                            <Close htmlColor={lightTheme.status.negative} />
                        </ActionButton>
                    </TopicInputContainer>
                ) : (
                    <AddTopicButton onClick={handleAddTopicClick}>
                        <Add style={{ color: lightTheme.primary.midnight, marginRight: '8px' }} />
                        Add a Topic
                    </AddTopicButton>
                )}
            </AddTopicContainer>

            <BottomNavigation>
                {session.isLobbyPhase ? (
                    <StartMeetingButton onClick={handleStartMeeting}>
                        {session.wasAgendaStarted ? 'Resume Agenda' : 'Start Agenda'}
                    </StartMeetingButton>
                ) : session.isConclusionPhase ? (
                    <Row centered={true}>
                        <NavButton onClick={() => sendCommand({ action: VideoAgentControlAction.Previous })}>
                            <ChevronLeft />
                            Last Topic
                        </NavButton>
                    </Row>
                ) : (
                    <Row centered={true}>
                        <NavButton onClick={() => sendCommand({ action: VideoAgentControlAction.Previous })}>
                            <ChevronLeft />
                            Last Topic
                        </NavButton>
                        <NavButton onClick={() => sendCommand({ action: VideoAgentControlAction.Next })}>
                            {session.isOnLastTopic ? 'Finish Agenda' : 'Next Topic'}
                            <ChevronRight />
                        </NavButton>
                    </Row>
                )}
            </BottomNavigation>
        </ControlContainer>
    );
}
