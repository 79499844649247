import { useCallback } from 'react';

import { getCommandContents } from '@spinach-shared/models';
import { ClientEventType, PostVideoAgentChatMessageRequest } from '@spinach-shared/types';

import { AGENT_SAYINGS, patchVideoAgentSession, postVideoAgentChatMessage } from '../../../..';
import { AgentCommandType, SayFunction } from '../../../types/agent';
import { useActivityTracking } from '../../useExperienceTracking';
import { useGlobalVideoAgent } from '../useGlobalVideoAgent';

export function isPreviousTopicCommand(message: string): boolean {
    const prevTopicCommands = [
        'previous topic',
        'back',
        '/topic back',
        '/agenda back',
        '/topic prev',
        '/agenda prev',
        'go back',
        '/spinach back',
        'spinach back',
    ];
    const { commandMatched: isPreviousTopicTrigger } = getCommandContents(message, prevTopicCommands);

    return !!isPreviousTopicTrigger;
}

export function usePreviousTopic(say: SayFunction) {
    const {
        state: { session, config },
        setSession,
    } = useGlobalVideoAgent();
    const trackActivity = useActivityTracking();

    // TODO pass in voice or chat trigger source
    return useCallback(
        async (triggerMedium = AgentCommandType.Voice) => {
            if (!session) {
                return;
            }

            const updatedSession = session.withBackToPreviousTopic();

            setSession(updatedSession);

            trackActivity(ClientEventType.VideoAgentActivity, 'Previous Topic', {
                TriggeredBy: triggerMedium,
                BackToLobby: updatedSession.currentTopic?.title ? 'No' : 'Yes',
                ...updatedSession?.analyticsPayload,
            });

            if (!session.settings.isAudioAcknowledgementDisabled) {
                new Audio(config.chimeSoundUrl).play();
            }

            let chatPayload: PostVideoAgentChatMessageRequest | null = null;
            if (updatedSession.currentTopic?.title) {
                chatPayload = {
                    message: `Going back to topic: ${updatedSession.currentTopic.title}`,
                    botId: updatedSession.botId,
                };
            }

            await Promise.all([
                patchVideoAgentSession(updatedSession.toJSON()),
                chatPayload ? postVideoAgentChatMessage(chatPayload) : undefined,
            ]);
        },
        [session, setSession, trackActivity, config]
    );
}
