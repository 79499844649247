import { Box, Tooltip, makeStyles } from '@material-ui/core';
import { CallMade, InfoOutlined } from '@material-ui/icons';
import React, { useState } from 'react';
import styled from 'styled-components';

import {
    ClientEventType,
    FeatureToggle,
    KnowledgeBaseIntegration,
    SpinachAPIPath,
    SpinachIntegration,
    WebUrlQuery,
} from '@spinach-shared/types';

import { getUser, postSetupKnowledgeBaseSummary } from '../../../../apis';
import DriveIlustration from '../../../../assets/drive-illustration.png';
import ConfluenceLogo from '../../../../assets/integrations/confluence-logo.png';
import GoogleDriveLogo from '../../../../assets/integrations/google-drive.png';
import NotionLogo from '../../../../assets/integrations/notion-logo.svg';
import { GlobalModal, GlobalModalMetadataType } from '../../../../atoms';
import {
    useDisabledKnowledgeBaseForFreeUsers,
    useExperienceTracking,
    useGlobalAuthedUser,
    useGlobalModal,
    useGoogleDriveEnablement,
    useIntegrationDetection,
    useProFeatures,
} from '../../../../hooks';
import { BodyRegularOnboard, ButtonSize, HeaderThree, lightTheme } from '../../../../styles';
import { URLUtil } from '../../../../utils';
import { getClientConfigValue } from '../../../../utils/getClientConfigValue';
import { Column, ProBadge, Row, Spacing, UpgradeLockIcon } from '../../../common';
import { OutlinedButton } from '../../../stand-up/OutlinedButton';
import { useNavigateToUpgrade } from '../../hooks/useNavigateToUpgrade';
import { TooltipContainer } from '../../onboarding/step-tracker-onboarding/shared';
import { IntegrationSettingsModalKind, IntegrationSettingsModalMetadata, UseHoverRowProps } from '../types';
import { ConnectedRowContents } from './ConnectedRowContents';
import { NotionPreAuthInstructionalModal } from './NotionPreAuthInstructionalModal';
import { UnfinishedRowContents } from './UnfinishedRowContents';
import { Label } from './common';

const Banner = styled(BodyRegularOnboard)`
    font-weight: 700;
    padding-left: 4px;
    padding-right: 4px;
    background-color: ${() => lightTheme.secondary.greenLight};
    color: ${() => lightTheme.neutrals.white};
`;

export const ComingSoonBanner = () => <Banner>Coming Soon!</Banner>;

export const CustomTooltip = styled(({ className, ...props }) => (
    <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
    '& .MuiTooltip-tooltip': {
        backgroundColor: 'transparent',
    },
}));

export function GoogleDriveIntegrationSection({
    setModalKind,
    hoverRowProps,
    isComingSoon = false,
    locked,
}: {
    setModalKind: (modalKind: IntegrationSettingsModalKind) => void;
    hoverRowProps: UseHoverRowProps;
    isComingSoon?: boolean;
    locked: boolean;
}): JSX.Element {
    const isDriveEnabled = useGoogleDriveEnablement();
    const [user, setUser] = useGlobalAuthedUser();
    const { getHoverableRowProps, hoveredRow } = hoverRowProps;
    const track = useExperienceTracking();
    const [_, setGlobalModal] = useGlobalModal();

    const startDetection = useIntegrationDetection((updatedUser) => {
        let updatedKnowledgeBaseIntegration: KnowledgeBaseIntegration | undefined;

        if (!user.isAuthedForGoogleDrive && updatedUser.isAuthedForGoogleDrive) {
            updatedKnowledgeBaseIntegration = SpinachIntegration.GoogleDrive;
        }

        if (updatedKnowledgeBaseIntegration) {
            const setup = async (knowledgeBaseIntegration: KnowledgeBaseIntegration) => {
                const response = await postSetupKnowledgeBaseSummary({ knowledgeBaseIntegration });
                const userResponse = await getUser();
                if (userResponse.user) {
                    setUser(userResponse.user);
                }

                setGlobalModal(GlobalModal.KnowledgeBaseSetupComplete, {
                    metadata: {
                        metadataType: GlobalModalMetadataType.KnowledgeBaseSetup,
                        knowledgeBaseIntegration,
                        setupFailed: !response || !!response?.setupFailed,
                    },
                });
            };
            setup(updatedKnowledgeBaseIntegration);
        }
    });

    const isGoogleDriveVideoUploadEnabled = user.featureToggles[FeatureToggle.UploadVideoToGoogleDrive];
    return isDriveEnabled ? (
        <>
            <Row vCenter {...getHoverableRowProps(SpinachIntegration.GoogleDrive)}>
                {locked ? (
                    <UpgradeLockIcon analyticsPrefix="Google drive" />
                ) : (
                    <img src={GoogleDriveLogo} style={{ width: '24px', height: '24px', marginRight: '15px' }} />
                )}
                <Label
                    style={{
                        opacity: locked ? 0.5 : 1,
                        width: isGoogleDriveVideoUploadEnabled ? '80px' : undefined,
                    }}
                >
                    Google
                </Label>
                {isGoogleDriveVideoUploadEnabled ? (
                    <Box width="95px" display="flex">
                        <CustomTooltip
                            enterTouchDelay={0}
                            leaveTouchDelay={15000}
                            onOpen={() => {
                                track(ClientEventType.AIDashboardActivity, {
                                    ClickedOn: `Google Drive info tooltip`,
                                });
                            }}
                            arrow
                            style={{ marginLeft: '5px' }}
                            interactive={true}
                            title={
                                <TooltipContainer
                                    style={{
                                        width: '300px',
                                        flexDirection: 'column',
                                    }}
                                >
                                    <Row centered>
                                        <img src={DriveIlustration} style={{ width: '300px', marginBottom: '10px' }} />
                                    </Row>
                                    <Row>
                                        <Column>
                                            <BodyRegularOnboard
                                                style={{
                                                    fontSize: '16px',
                                                    textAlign: 'left',
                                                }}
                                            >
                                                Store meeting summaries and video recordings to your Google Drive
                                            </BodyRegularOnboard>
                                        </Column>
                                    </Row>
                                </TooltipContainer>
                            }
                            placement="top"
                        >
                            <InfoOutlined
                                style={{ width: '24px', height: '24px' }}
                                htmlColor={lightTheme.primary.greenLight}
                            />
                        </CustomTooltip>
                    </Box>
                ) : null}
                {user.isAuthedForGoogleDrive && !isComingSoon ? (
                    <ConnectedRowContents
                        label={'Connected'}
                        isSettingsVisible={hoveredRow === SpinachIntegration.GoogleDrive}
                        onSettingsClick={async () => {
                            setModalKind(IntegrationSettingsModalKind.SettingsGoogleDrive);
                            track(ClientEventType.AIDashboardClick, {
                                ClickedOn: `Open Remove Google Drive Modal`,
                            });
                        }}
                    />
                ) : isComingSoon ? (
                    <ComingSoonBanner />
                ) : locked ? (
                    <></>
                ) : (
                    <OutlinedButton
                        title={'Connect'}
                        onClick={() => {
                            track(ClientEventType.AIDashboardClick, {
                                ClickedOn: `Google Drive Connect`,
                            });
                            track(ClientEventType.UserAuthorizeGoogleDriveClick);
                            startDetection();
                            URLUtil.openURL(
                                `${getClientConfigValue('REACT_APP_AUTH_URL')}${SpinachAPIPath.GoogleDriveAuth}`
                            );
                        }}
                        size={ButtonSize.Mini}
                        endIcon={<CallMade style={{ fontSize: '14px', marginLeft: '5px' }} />}
                        labelStyles={{
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    />
                )}
            </Row>
        </>
    ) : (
        <></>
    );
}

export function ConfluenceIntegrationSection({
    setModalKind,
    setModalMetadata,
    hoverRowProps,
    isComingSoon = false,
    locked,
}: {
    setModalKind: (modalKind: IntegrationSettingsModalKind) => void;
    setModalMetadata: (metadata: IntegrationSettingsModalMetadata) => void;
    hoverRowProps: UseHoverRowProps;
    isComingSoon?: boolean;
    locked: boolean;
}): JSX.Element {
    const [user] = useGlobalAuthedUser();
    const { getHoverableRowProps, hoveredRow } = hoverRowProps;
    const track = useExperienceTracking();

    const startDetection = useIntegrationDetection((updatedUser) => {
        let updatedKnowledgeBaseIntegration: KnowledgeBaseIntegration | undefined;

        if (!user.isAuthedForConfluence && updatedUser.isAuthedForConfluence) {
            updatedKnowledgeBaseIntegration = SpinachIntegration.Confluence;
        }

        if (updatedKnowledgeBaseIntegration) {
            setModalMetadata({ [IntegrationSettingsModalKind.SettingsConfluence]: { isFirstTimeAuth: true } });
            setModalKind(IntegrationSettingsModalKind.SettingsConfluence);
        }
    });

    return (
        <Row vCenter {...getHoverableRowProps(SpinachIntegration.Confluence)}>
            {locked ? (
                <UpgradeLockIcon analyticsPrefix="Confluence" />
            ) : (
                <img src={ConfluenceLogo} style={{ width: '24px', height: '24px', marginRight: '15px' }} />
            )}
            <Label style={{ opacity: locked ? 0.5 : 1 }}>Confluence</Label>
            {user.isAuthedForConfluence && !isComingSoon ? (
                <ConnectedRowContents
                    label={`Connected${
                        user.integrationSettings?.confluenceSettings?.confluenceAccountUrl
                            ? `: ${user.integrationSettings?.confluenceSettings?.confluenceAccountUrl?.replace(
                                  'https://',
                                  ''
                              )}`
                            : ''
                    }`}
                    isSettingsVisible={hoveredRow === SpinachIntegration.Confluence}
                    onSettingsClick={async () => {
                        setModalKind(IntegrationSettingsModalKind.SettingsConfluence);
                        track(ClientEventType.AIDashboardClick, {
                            ClickedOn: `Open Remove Confluence Modal`,
                        });
                    }}
                />
            ) : isComingSoon ? (
                <ComingSoonBanner />
            ) : locked ? (
                <></>
            ) : (
                <OutlinedButton
                    title={'Connect'}
                    onClick={() => {
                        track(ClientEventType.AIDashboardClick, {
                            ClickedOn: `Confluence Connect`,
                        });
                        startDetection();
                        URLUtil.openURL(
                            `${getClientConfigValue('REACT_APP_AUTH_URL')}${SpinachAPIPath.IntegrationAuth.replace(
                                ':oauthProvider',
                                SpinachIntegration.Confluence
                            )}?${WebUrlQuery.Suid}=${user.spinachUserId}`,
                            { redirectThroughBackendWhenOnZoom: true }
                        );
                    }}
                    size={ButtonSize.Mini}
                    endIcon={<CallMade style={{ fontSize: '14px', marginLeft: '5px' }} />}
                    labelStyles={{
                        display: 'flex',
                        alignItems: 'center',
                    }}
                />
            )}
        </Row>
    );
}

export function NotionIntegrationSection({
    setModalKind,
    hoverRowProps,
    locked,
}: {
    setModalKind: (modalKind: IntegrationSettingsModalKind) => void;
    hoverRowProps: UseHoverRowProps;
    locked: boolean;
}): JSX.Element {
    const [user, setUser] = useGlobalAuthedUser();
    const { getHoverableRowProps, hoveredRow } = hoverRowProps;
    const track = useExperienceTracking();
    const [_, setGlobalModal] = useGlobalModal();

    const startDetection = useIntegrationDetection((updatedUser) => {
        let updatedKnowledgeBaseIntegration: KnowledgeBaseIntegration | undefined;

        if (!user.isAuthedForNotion && updatedUser.isAuthedForNotion) {
            updatedKnowledgeBaseIntegration = SpinachIntegration.Notion;
        }

        if (updatedKnowledgeBaseIntegration) {
            const setup = async (knowledgeBaseIntegration: KnowledgeBaseIntegration) => {
                const response = await postSetupKnowledgeBaseSummary({ knowledgeBaseIntegration });
                const userResponse = await getUser();
                if (userResponse.user) {
                    setUser(userResponse.user);
                }

                setGlobalModal(GlobalModal.KnowledgeBaseSetupComplete, {
                    metadata: {
                        metadataType: GlobalModalMetadataType.KnowledgeBaseSetup,
                        knowledgeBaseIntegration,
                        setupFailed: !response || !!response?.setupFailed,
                    },
                });
            };
            setup(updatedKnowledgeBaseIntegration);
        }
    });

    const [isNotionPreAuthInstructionalModalOpen, setIsNotionPreAuthInstructionalModalOpen] = useState(false);

    const isUserUsingOldNotionIntegration =
        user.integrationSettings?.notionSettings?.isAuthed &&
        user.integrationSettings.notionSettings.notionSummarySetupFailed === undefined;
    return (
        <>
            <Row vCenter {...getHoverableRowProps(SpinachIntegration.Notion)}>
                {locked ? (
                    <UpgradeLockIcon analyticsPrefix="Notion" />
                ) : (
                    <img src={NotionLogo} style={{ width: '24px', height: '24px', marginRight: '15px' }} />
                )}
                <Label style={{ opacity: locked ? 0.5 : 1 }}>Notion</Label>
                {user.isAuthedForNotion && !user.integrationSettings?.notionSettings?.notionSummarySetupFailed ? (
                    isUserUsingOldNotionIntegration ? (
                        <UnfinishedRowContents
                            label={`Update`}
                            isSettingsVisible={hoveredRow === SpinachIntegration.Notion}
                            onSettingsClick={async () => {
                                setModalKind(IntegrationSettingsModalKind.SettingsNotion);
                                track(ClientEventType.AIDashboardClick, {
                                    ClickedOn: `Open Notion Settings`,
                                });
                            }}
                        />
                    ) : (
                        <ConnectedRowContents
                            label={
                                user.integrationSettings?.notionSettings?.defaultDatabaseName
                                    ? `Connected: ${user.integrationSettings?.notionSettings?.defaultDatabaseName}`
                                    : 'Connected'
                            }
                            isSettingsVisible={hoveredRow === SpinachIntegration.Notion}
                            onSettingsClick={async () => {
                                setModalKind(IntegrationSettingsModalKind.SettingsNotion);
                                track(ClientEventType.AIDashboardClick, {
                                    ClickedOn: `Open Remove Notion Modal`,
                                });
                            }}
                        />
                    )
                ) : locked ? (
                    <></>
                ) : (
                    <OutlinedButton
                        title={'Connect'}
                        onClick={() => {
                            track(ClientEventType.AIDashboardClick, {
                                ClickedOn: `Notion Connect`,
                            });
                            setIsNotionPreAuthInstructionalModalOpen(true);
                        }}
                        size={ButtonSize.Mini}
                        endIcon={<CallMade style={{ fontSize: '14px', marginLeft: '5px' }} />}
                        labelStyles={{
                            display: 'flex',
                            alignItems: 'center',
                        }}
                    />
                )}
            </Row>
            <NotionPreAuthInstructionalModal
                isOpen={isNotionPreAuthInstructionalModalOpen}
                setIsOpen={setIsNotionPreAuthInstructionalModalOpen}
                onConfirm={() => {
                    startDetection();

                    URLUtil.openURL(
                        `${getClientConfigValue('REACT_APP_AUTH_URL')}${SpinachAPIPath.IntegrationAuth.replace(
                            ':oauthProvider',
                            SpinachIntegration.Notion
                        )}?${WebUrlQuery.Suid}=${user.spinachUserId}`,
                        { redirectThroughBackendWhenOnZoom: true }
                    );
                    setIsNotionPreAuthInstructionalModalOpen(false);
                }}
            />
        </>
    );
}

const useTooltipStyles = makeStyles(() => ({
    tooltip: {
        background: 'black',
        fontSize: '15px',
        padding: '10px',
    },
    arrow: {
        color: 'black',
    },
}));

const UpgradeToolTipWrapper = ({
    analyticsPrefix,
    children,
}: {
    children: React.ReactElement;
    analyticsPrefix: string;
}) => {
    const navigateToUpgrade = useNavigateToUpgrade();
    const hasProFeatures = useProFeatures();
    const tooltipClasses = useTooltipStyles();
    return (
        <Tooltip
            classes={tooltipClasses}
            interactive
            arrow
            title={
                hasProFeatures ? (
                    ''
                ) : (
                    <Box display="flex" flexDirection="row" onClick={() => {}}>
                        <span>
                            {'Upgrade on your '}
                            <a
                                style={{ color: '#267764', cursor: 'pointer', textDecoration: 'underline' }}
                                onClick={() => navigateToUpgrade(`${analyticsPrefix} click upgrade`)}
                            >
                                {'Account page'}
                            </a>
                            {' to unlock advanced features and integrations.'}
                        </span>
                    </Box>
                )
            }
            placement="top"
            style={{ flexDirection: 'column' }}
        >
            {children}
        </Tooltip>
    );
};

export function KnowledgeBaseIntegrationSection({
    setModalKind,
    setModalMetadata,
    hoverRowProps,
}: {
    setModalKind: (modalKind: IntegrationSettingsModalKind) => void;
    setModalMetadata: (metadata: IntegrationSettingsModalMetadata) => void;
    hoverRowProps: UseHoverRowProps;
}): JSX.Element {
    const hasProFeatures = useProFeatures();
    const disabledKnowledgeBaseForFreeUsers = useDisabledKnowledgeBaseForFreeUsers();
    const locked = disabledKnowledgeBaseForFreeUsers && !hasProFeatures;
    return (
        <>
            <Row>
                <HeaderThree>Knowledge Base</HeaderThree>
                {disabledKnowledgeBaseForFreeUsers ? <ProBadge /> : <></>}
            </Row>
            <Spacing factor={1 / 2} />
            <Row>
                <BodyRegularOnboard>Keep your summaries in a spot</BodyRegularOnboard>
            </Row>
            <Spacing factor={1 / 2} />
            <ConfluenceIntegrationSection
                locked={locked}
                setModalMetadata={setModalMetadata}
                setModalKind={setModalKind}
                hoverRowProps={hoverRowProps}
            />
            <Spacing factor={1 / 2} />
            <NotionIntegrationSection locked={locked} setModalKind={setModalKind} hoverRowProps={hoverRowProps} />
            <Spacing factor={1 / 2} />
            <GoogleDriveIntegrationSection locked={locked} setModalKind={setModalKind} hoverRowProps={hoverRowProps} />
        </>
    );
}
