import { useCallback, useEffect } from 'react';
import { useRecoilState } from 'recoil';

import { MILLIS_IN_SECONDS } from '@spinach-shared/constants';
import { AddScribeToAllMeetingKind, SpinachAPIPath } from '@spinach-shared/types';

import { getSpinachAPI } from '../apis';
import {
    MeetingAutomationInProgressState,
    atomMeetingAutomationInProgressState,
} from '../atoms/atomMeetingAutomationInProgressState';

export function useSchedulingAutomationInProgressState(): {
    schedulingAutomationInProgressState: MeetingAutomationInProgressState;
    pollForStateUntilCompletion: () => void;
} {
    const [schedulingAutomationInProgressState, setSchedulingAutomationInProgressState] = useRecoilState(
        atomMeetingAutomationInProgressState
    );

    async function fetchAndSetInProgressState() {
        const response = await getSpinachAPI<AddScribeToAllMeetingKind | null>(
            SpinachAPIPath.GetSchedulingAutomationInProgressState
        );
        const kind = response || null;

        setSchedulingAutomationInProgressState({ kind });
        return kind;
    }

    useEffect(() => {
        fetchAndSetInProgressState();
    }, []);

    const pollForStateUntilCompletion = useCallback(() => {
        const intervalId = setInterval(async () => {
            const state = await fetchAndSetInProgressState();
            if (state === null) {
                clearInterval(intervalId);
            }
        }, MILLIS_IN_SECONDS);

        return () => {
            if (intervalId) {
                clearInterval(intervalId);
            }
        };
    }, []);

    return {
        schedulingAutomationInProgressState,
        pollForStateUntilCompletion,
    };
}
