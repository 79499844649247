import { useEffect } from 'react';
import { useSearchParams } from 'react-router-dom';

import { AddScribeToAllMeetingKind, ClientEventType, WebQueryRedirect, WebUrlQuery } from '@spinach-shared/types';

import {
    GlobalModal,
    patchRestartTrial,
    patchUser,
    useExperienceTracking,
    useGlobalAuthedUser,
    useGlobalModal,
} from '../..';
import { updateAutomatedScribeSettingsOnCalendarEvents } from '../apis/calendar/updateAutomatedScribeSettingsOnCalendarEvents';
import { useSchedulingAutomationInProgressState } from './useSchedulingAutomationInProgressState';

export function useActivateTrial() {
    const [searchParams, setSearchParams] = useSearchParams();
    const [user, setUser] = useGlobalAuthedUser();
    const startTrial = searchParams.get(WebUrlQuery.StartTrial);
    const redirect = searchParams.get(WebUrlQuery.Redirect);
    const track = useExperienceTracking();
    const [modal, setGlobalModal] = useGlobalModal();
    const { schedulingAutomationInProgressState, pollForStateUntilCompletion } =
        useSchedulingAutomationInProgressState();
    useEffect(() => {
        async function activateTrial() {
            if (user.isOnLiveReverseTrial || user.isPayingUser || user.hasProFeatures) {
                return;
            }

            if (startTrial !== 'true') {
                if (redirect === WebQueryRedirect.ActivateTrial) {
                    if (modal !== GlobalModal.ActivateTrial) {
                        setGlobalModal(GlobalModal.ActivateTrial);
                    }

                    const updatedSearchParams = new URLSearchParams(searchParams);
                    updatedSearchParams.delete(WebUrlQuery.Redirect);
                    setSearchParams(updatedSearchParams);
                }
                return;
            }

            // check for calendar conditions
            if (!user.isUsingRecallV2 || !user.isAuthedForAnyCalendar) {
                // if needs calendar permissions show modal
                if (modal !== GlobalModal.CalendarAccessNeededForTrial) {
                    setGlobalModal(GlobalModal.CalendarAccessNeededForTrial);
                }
                return;
            }

            // restart trial
            // TODO: how to protect from double activation?
            const response = await patchRestartTrial();
            if (response.user) {
                setUser(response.user);
            }

            // start a2a if needed
            const schedulingAutomationConfig = user.metadata.automaticAddScribeConfiguration;
            const userAlreadyHasSomeSchedulingAutomation =
                schedulingAutomationConfig &&
                'kind' in schedulingAutomationConfig &&
                schedulingAutomationConfig.kind !== null;
            const addToAllInProgress = schedulingAutomationInProgressState.kind === AddScribeToAllMeetingKind.All;
            if (!userAlreadyHasSomeSchedulingAutomation && !addToAllInProgress) {
                // we don't want to wait for this to finish, this runs in the background
                updateAutomatedScribeSettingsOnCalendarEvents({ kind: AddScribeToAllMeetingKind.All })
                    .then(async () => {
                        const updatedUserResponse = await patchUser({
                            metadata: {
                                automaticAddScribeConfiguration: { kind: AddScribeToAllMeetingKind.All },
                            },
                        });
                        if (!!updatedUserResponse.user) {
                            setUser(updatedUserResponse.user);
                        }
                    })
                    .catch((error) => {
                        track(ClientEventType.AIDashboardUnhappyPath, {
                            Error: error.message,
                            Flow: 'Enable AutoRecord during trial restart',
                        });
                    });
                // update config
                pollForStateUntilCompletion();
            }

            // remove the query param
            const updatedSearchParams = new URLSearchParams(searchParams);
            updatedSearchParams.delete(WebUrlQuery.StartTrial);
            setSearchParams(updatedSearchParams);

            // show all set modal
            setGlobalModal(GlobalModal.FinishedActivatingTrial);
        }

        activateTrial();
    }, [
        startTrial,
        user.isUsingRecallV2,
        pollForStateUntilCompletion,
        schedulingAutomationInProgressState.kind,
        setGlobalModal,
        user.metadata.automaticAddScribeConfiguration,
        user.isAuthedForAnyCalendar,
        setSearchParams,
        searchParams,
        user.isOnLiveReverseTrial,
        modal,
        redirect,
        setUser,
        track,
        user.isPayingUser,
        user.hasProFeatures,
    ]);
}
